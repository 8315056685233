<template>
  <!--
    Used to vonfigure the Camera Tester
  -->
  <div class="cameraTesterConfigure">
    <h4 class="m-0 mt-3">
      {{ $t('add') }}
    </h4>
    <hr class="m-0 mb-3">
    <div class="form-group m-form__group row mb-4">
      <label class="col-4 col-form-label">{{ $t('camera') }}</label>
      <div class="col-8">
        <select
          v-model="cameraId"
          class="form-control"
        >
          <option
            :value="null"
            disabled
          >
            {{ $t('pleaseSelectOne') }}
          </option>
          <option
            v-for="(camera, index) in cameras"
            :key="`cameraTester-cameraOption-${ index }`"
            :value="camera.id"
          >
            {{ `${ camera.cameraType } ${ camera.ipAddress } (Id: ${ camera.id })` }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group m-form__group row mb-2">
      <label class="col-4 col-form-label">{{ $t('testDuration') }}</label>
      <div class="col-8">
        <div class="input-group mb-3">
          <select
            v-model="testDuration"
            class="form-control"
          >
            <option
              disabled
              :value="null"
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option :value="10">
              10
            </option>
            <option :value="20">
              20
            </option>
            <option :value="30">
              30
            </option>
            <option :value="60">
              60
            </option>
          </select>
          <div class="input-group-append">
            <span
              id="testDurationAppend"
              class="input-group-text"
            >{{ testDurationAppendText }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group m-form__group row mb-2">
      <label class="col-4 col-form-label">{{ $t('testType') }}</label>
      <div class="col-8">
        <div class="input-group mb-3">
          <select
            v-model="testType"
            class="form-control"
          >
            <option
              disabled
              value="null"
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option value="consecutive">
              {{ $t('consecutive').toLowerCase() }}
            </option>
            <option value="parallel">
              {{ $t('parallel').toLowerCase() }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="form-group m-form__group row mb-2">
      <span class="col-4 col-form-label" />
      <div class="col-8">
        <div class="input-group mb-3">
          <span v-if="testType == 'parallel'">
            <i>parallel means both streams will be fetched at the same time</i>
          </span>
          <span v-if="testType == 'consecutive'">
            <i>consecutive means streams will be fetched one after another</i>
          </span>
        </div>
      </div>
    </div>

    <button
      :disabled="!cameraId"
      class="btn btn-sm btn-primary float-right"
      @click="addStreamReportOrder()"
    >
      <font-awesome-icon
        class="mr-2"
        icon="plus"
        style="vertical-align: middle;"
      />
      {{ $t('add') }}
    </button>
    <div class="clearfix" />
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "EditVideoRecorderConfiguration",
  mixins: [
    errorMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return {
      cameraId: null,
      testDuration: 30,
      testType: "parallel",
      cancelAddSource: null,
      //guId: null,
      cameras: null
    }
  },
  computed: {
    testDurationAppendText () {
      if (this.testType == 'consecutive') {
        return 'seconds × 2';
      }
      return 'seconds';
    }
  },
  created () {
    this.getCameras();
  },
  beforeDestroy () {
    this.cancelAddRequest();
  },
  methods: {
    getCameras () {
      let url = `/CameraManagement/GetAllCameras?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url)
        .then((cameraResponse) => {
          if (cameraResponse == null) {
            return;
          }
          if (cameraResponse.data == null) {
            return;
          }
          if (cameraResponse.data.length == 0) {
            return;
          }
          this.cameras = cameraResponse.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addStreamReportOrder () {
      this.cancelAddRequest();
      this.cancelAddSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.cancelAddSource.token };
      let url = `/CameraManagement/AddStreamReportOrder?installationId=${ this.installationId }&cameraId=${ this.cameraId }&testDuration=${ this.testDuration }&testType=${ this.testType }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url, requestConfig)
        .then((response) => {
          if (response == null) {
            return;
          }
          this.cancelAddSource = null;
          if (response.data == null) {
            return;
          }
          if (response.data.reportId == null) {
            return;
          }
          //this.guId = response.data.reportId;
          this.$emit("reload");
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
        });
    },
    cancelAddRequest () {
      if (this.cancelAddSource) {
        this.cancelAddSource.cancel();
      }
    },
  }
}
</script>
